@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;

}

.container {
  width: 100%;
  height: 100vh;
  background: rgba(238, 242, 244, 0.5);
  padding: 20px 100px;
}

.form-container {
  display: flex;
  height: 100%;
}
.form-left {
  width: 50%;
  height: 100%;
  background-color: #362fd9;
  border-radius: 20px 0px 0px 20px;
}

.form-right {
  width: 50%;
  background-color: white;
  border-radius: 0px 20px 20px 0px;
}

.form-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
}

.logo {
  display: block;
  margin: auto;
}

.form-text {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 500;
  display: block;
  margin-top: 20px;
  text-align: center;
}

.form-para {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 300;
  display: block;
  margin-top: 5px;
  text-align: center;
}
.form-input-div {
  position: relative;
  display: block;
  margin-top: 20px;
}
.form-input {
  border: none;
  border-bottom: 0.55px solid #c0c0c0;
  width: 100%;
  border-radius: 0px;
  background: transparent;
  font-family: "Montserrat";
  font-style: normal;
  color: #c0c0c0;
  margin-top: 20px;
  z-index: 99;
}
.form-input:focus + span {
  font-size: 10px;
  color: black;
  top: 0px;
}
.form-input:focus {
  border-bottom: 0.55px solid #000000;
  color: #000000;
}
.form-span {
  position: absolute;
  top: 25px;
  left: 0px;
  font-family: "Montserrat";
  font-style: normal;
  color: #c0c0c0;
  transition: 0.3s;
  pointer-events: none;
}
.form-span:checked {
  font-size: 10px;
  color: black;
  top: 0px;
}

.form-submit-button {
  display: block;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 300;
  border-radius: 10px;
  color: #ffffff;
  background: #362fd9;
  border: none;
  width: 70%;
  margin: auto;
  margin-top: 40px;
  padding: 10px 0px;
}

.user-container {
  background: #362fd9;
  width: 100%;
  height: 100%;
}
.slogan {
  width: 200px;
  height: 50px;
}
.detail-container {
  width: 390px;
  height: 844px;
  margin: auto;
  background: #ffffff;
  padding: 50px 14px;
  background: #ffffff;
}

.detail-logo-container {
  display: flex;
  justify-content: space-between;
}

.detail-content-container {
  display: flex;
  gap: 22px;
  position: relative;
}

.detail-image {
  width: 90px;
  height: 90px;
}

.detail-background-image {
  background: url("../assets/background.png") no-repeat 0px -50px;
  background-size: cover;
  width: 100%;
  height: 180px;
  position: relative;
  margin-top: 22px;
  padding-top: 30px;
}

.detail-title {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-size: 21.9572px;
  line-height: 28px;
  color: #202020;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.detail-designation {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 13.9728px;
  line-height: 18px;
  color: #202020;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.border {
  position: absolute;
  left: 30px;
  height: 90px;
}
.detail-sociallinks {
  width: 200px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.detail-sociallinks img {
  cursor: pointer;
}
.share-button {
  background-color: #ffffff;
  padding: 8px 26px;
  margin-top: 22px;
  width: 143px;
  cursor: pointer;
}

.share-button h3 {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  border: none;
  background: linear-gradient(119.27deg, #045ee1 32.04%, #25cbb6 159.21%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.detail-social-detail {
  margin-top: 20px;
  background: url("../assets/socback.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.detail-social-detail img {
  width: 35px;
  height: 35px;
}
.detail-social-detail h5 {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #202020;
  margin-left: 15px;
}

.save-contact-button {
  background: linear-gradient(93.45deg, #25cbb6 4.24%, #045ee1 90.89%);
  backdrop-filter: blur(18.2653px);
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  padding: 18px 0px;
  margin-top: 20px;
  border: none;
}

.get-card {
  object-fit: cover;
  margin-top: 20px;
  width: 100%;
}

.error {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 300;
  margin-top: 20px;
  color: red;
  text-align: center;
}
