@media (max-width: 400px) {
    .MuiSnackbarContent-message{
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .MuiPaper-root {
        padding: 6px 13px !important;

    }
}